import { httpRequest } from '@/utils/httpRequest'

let headerObj = {
  'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0',
  'Content-Type': 'application/json;charset=utf-8',
  'Blade-Auth': 'bearer ' + sessionStorage.getItem('access_token'),
}


export const getLogin = data => {
  let username = prompt("请输入登录账号（测试环境）")
  let params = {
    tenantId: '000000',
    // username: 'hr',
    // password: 'adab7b701f23bb82014c8506d3dc784e',
    // grant_type: 'captcha',
    // type: "account",
    // scope: "all",
    username,
    password: 'fcf713ba452f299952f2317a9a512e5d',
    grant_type: 'password',
    // type: "account",
    // scope: "all",
  }
  let path = '/blade-auth/oauth/token' + JSON.stringify(params).replace(/:/g, '=')
    .replace(/,/g, '&')
    .replace(/{/g, '?')
    .replace(/}/g, '')
    .replace(/"/g, '')

  return httpRequest.post(path, data, { 'Tenant-Id': '000000' })
}

// 首页未读消息
export const getUnReadCount = () => {
  return httpRequest.post('/blade-dcpp/dcpp-remind/queryUnReadCount', {}, headerObj)
}