import axios from 'axios'
import router from '@/router'

const prefix = '/api'
axios.defaults.timeout = 30000


let defaultContentType = 'application/json;charset=UTF-8'
/**
 *  get请求
 * @param restApi 请求的rest api
 * @param params 请求的requestParams参数
 * @returns {Promise<any>}
 */
const get = (restApi, params = {}, headerObj = {}, timeOut, contentType) => {
  if (!timeOut) {
    timeOut = axios.defaults.timeout
  }
  settingContentType(contentType)
  return new Promise((resolve, reject) => {
    axios.get(prefix + restApi, { params: params, timeout: timeOut || 1000 * 60 * 30, headers: {
      'Content-Type': defaultContentType,
      'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
      'Blade-Auth': 'bearer ' + '',
      ...headerObj,
    } }).then(response => {
      resolve(response.data)
    })
      .catch(err => {
        console.log(err)
        if (err.response.data.code == 401) {
          alert('登录已过期！请重新登录！')
          sessionStorage.removeItem('access_token')
          sessionStorage.removeItem('userInfo')
          router.replace('/home')
        }
        reject(err)
      })
  })
}

/**
 *  post请求
 * @param restApi 请求的rest api
 * @param data 请求的数据
 * @returns {Promise<any>}
 */
const post = (restApi, data = {}, headerObj = {}, timeOut, contentType ) => {
  if (!timeOut) {
    timeOut = axios.defaults.timeout
  }
  settingContentType(contentType)
  return new Promise((resolve, reject) => {
    axios.post(prefix + restApi, data, {
      timeout: timeOut || 1000 * 60 * 30,
      headers: {
        'Content-Type': defaultContentType,
        'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        'Blade-Auth': 'bearer ' + '',
        ...headerObj,
      },
    }).then(response => {
      // console.log(response);
      resolve(response.data)
    }, err => {
      console.log(err.response.data)
      if (err.response.data.code == 401) {
        alert('登录已过期！请重新登录！')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('userInfo')
        router.replace('/home')
      }
      reject(err)
    })
  })
}

/**
 *  patch请求
 * @param restApi 请求的rest api
 * @param data 请求的数据
 * @returns {Promise<any>}
 */
const patch = (restApi, data = {}, timeOut, contentType) => {
  if (!timeOut) {
    timeOut = axios.defaults.timeout
  }
  settingContentType(contentType)
  return new Promise((resolve, reject) => {
    axios.patch(prefix + restApi, data, timeOut).then(response => {
      resolve(response.data)
    }, err => {
      if (err.response.data.code == 401) {
        alert('登录已过期！请重新登录！')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('userInfo')
        router.replace('/home')
      }
      reject(err)
    })
  })
}

/**
 *  put请求
 * @param restApi 请求的rest api
 * @param data 请求的数据
 * @returns {Promise<any>}
 */
const put = (restApi, data = {}, timeOut, contentType) => {
  if (!timeOut) {
    timeOut = axios.defaults.timeout
  }
  settingContentType(contentType)
  return new Promise((resolve, reject) => {
    axios.put(prefix + restApi, data, timeOut).then(response => {
      resolve(response.data)
    }, err => {
      if (err.response.data.code == 401) {
        alert('登录已过期！请重新登录！')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('userInfo')
        router.replace('/home')
      }
      reject(err)
    })
  })
}

/**
 *  put请求
 * @param restApi 请求的rest api
 * @param data 请求的数据
 * @returns {Promise<any>}
 */
const del = (restApi, data = {}, timeOut, contentType) => {
  if (!timeOut) {
    timeOut = axios.defaults.timeout
  }
  settingContentType(contentType)
  return new Promise((resolve, reject) => {
    axios.delete(prefix + restApi, data, timeOut).then(response => {
      resolve(response.data)
    }, err => {
      if (err.response.data.code == 401) {
        alert('登录已过期！请重新登录！')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('userInfo')
        router.replace('/home')
      }
      reject(err)
    })
  })
}

function settingContentType(contentType) {
  defaultContentType = contentType ? contentType : 'application/json;charset=UTF-8'
}

const custom = (method, restApi, data = {}, timeOut, contentType) => {
  if (method == 'post') {
    return post(restApi, data, timeOut, contentType)
  } else if (method == 'get') {
    return get(restApi, data, timeOut, contentType)
  }
}

// 暴露服务出去
export const httpRequest = {
  get,
  post,
  patch,
  put,
  del,
  custom,
}
