import Vue from 'vue'
import Vuex from 'vuex'
import { getMessagePage } from '@/api/individualCenter/index'
import { getUnReadCount } from '@/api/user/index'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userCardNo: '', // 用户身份证
    messageNum: 0,
  },
  getters: {
    getUserCardNo: state => {
      return state.userCardNo
    },
    getMessageNum: state => {
      return state.messageNum
    },
  },
  // 同步修改数据
  mutations: {
    SETUSERCARDNO: (state, payload) => {
      state.userCardNo = payload.cardNo
    },
    SETMESSAGENUM: (state, payload) => {
      getUnReadCount().then(res => {
        if (res.code == 200) {
          state.messageNum = res.data
        }
      }, err => {
        console.log(err)
      })
    },
  },
  // 异步修改数据
  actions: {
    setUserCardNo: (context, payload) => {
      context.commit('SETUSERCARDNO', payload)
    },
    setMessageNum: (context, payload) => {
      context.commit('SETMESSAGENUM', payload)
    },
  },
})

// export default store