<template>
  <div class="sidebar">
    <div class="sidebar-top">
      <div class="top-title">{{ title }}</div>
      <div class="top-sub">{{ sub }}</div>
    </div>
    <ul class="sidebar-content">
      <li v-for="(item, index) in list"
        :key="index"
        :class="['item', {'active': item.value === value }]"
        @click="handleItem(item)">
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    sub: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [
        { label: '正和简介', value: 'introduce' },
        { label: '组织架构', value: 'introdue' },
        { label: '正和章程', value: 'introdue' },
        { label: '工作制度', value: 'introdue' },
        { label: '业务流程', value: 'introuce' },
      ],
    },
    value: { // 格式
      type: [String, Number],
      default: '0',
    },
  },
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleItem(row) {
      this.$emit('input', row.value)
      this.$emit('handleItem', row)
    },
  },
}
</script>

<style lang="less" scoped>
@outwidth: 75rem;
@lightColor: #9F7A3E;
.sidebar {
    width: 15rem;
    height: 22.5rem;
    padding: 0.625rem;
    border: 1px solid #EEEEEE;
    background: linear-gradient(0deg, #FFFFFF 90%, #FDF2DF 100%);
    margin-right: 2.8125rem;
    .sidebar-top {
        padding: 0.875rem 1.5rem 1.5rem;
        .top-title {
            color: @lightColor;
            font-size: 1.5rem;
            min-width: 120px;
        }
        .top-sub {
            font-size: 1.125rem;
            color: @lightColor;
        }
    }
    .sidebar-content {
        .item {
            font-size: 1.125rem;
            height: 3.125rem;
            padding-left: 2.0625rem;
            line-height: 3.125rem;
            border-bottom: 1px solid #EEEEEE;
            cursor: pointer;
            &:last-child {
                border-bottom: none;
            }
        }
        .item.active {
            background: #FDF4E2;
            color: @lightColor;
        }
    }
}
</style>