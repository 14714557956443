import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from '@/router/index'
import sidePage from '@/components/side-page'
import translator from '@/utils/translator'
// import { cn_trans_translate_select } from '@/utils/translator';

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(translator)

Vue.use(VueRouter)
Vue.component('SidePage', sidePage)

import store from '@/store/index'
// cn_trans_translate_select(0)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
