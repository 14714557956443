<template>
  <div class="bg">
    <div class="header">
      <div class="bread">首页 /  <span class="light-color">{{ nowName }}</span></div>
    </div>
    <div class="body">
      <sideBar :title="nowName"
        :sub="sub"
        :list="list"
        v-model="nowValue"
        @handleItem="handleItem" />
      <div class="main">
        <router-view></router-view>
        <!-- <slot></slot>   -->
      </div>
    </div>
  </div>
</template>

<script>
import sideBar from '@/components/side-bar/index.vue'
export default {
  components: { sideBar },
  props: {
    nowName: {
      type: String,
      default: '党建专栏',
    },
    sub: {
      type: String,
      default: 'ABOUT ZHUANLAN',
    },
    list: {
      type: Array,
      default: () => [
        { label: '党建专栏', value: 'column' },
      ],
    },
  },
  data() {
    return {
      //   list: [
      //     {label: '党建专栏', value: 'column'},
      //   ],
      nowValue: '',
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
    // nowName() {
    //   // return this.$route.name
    //   return '党建专栏'
    // },
  },
  mounted() {
    let it = this.list.find(item => this.path.includes(item.value))
    this.nowValue = it && it.value
    if (this.$route.matched && this.$route.matched[1]) {
    }
  },
  methods: {
    handleItem(item) {
      let it = this.list.find(item => item.value === this.nowValue)
      this.$router.push({ name: it.label })
    },
  },
}
</script>

  <style lang="less" scoped>
  @outwidth: 75rem;
  @lightColor: #9F7A3E;
  .bg {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
  }
  .header {
      border-bottom: 1px solid #EEEEEE;
      width: 100%;
  }
  .bread {
    line-height: 3.125rem;
    width: @outwidth;
    color: #999999;
    margin: 0 auto;
  }
  .body {
    font-family: Microsoft YaHei;
    padding: 1.875rem 0;
    width: @outwidth;
    display: flex;

    .main {
      flex: 1;
    }
  }
  .light-color {
    color: @lightColor;
  }
  </style>