import { httpRequest } from '@/utils/httpRequest'

let headerObj = {
  'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0',
  'Content-Type': 'application/json;charset=utf-8',
  'Blade-Auth': 'bearer ' + sessionStorage.getItem('access_token'),
}

// 投诉分页
export const getComplaintPage = data => {
  return httpRequest.post('/blade-dcpp/complaint/page', data, headerObj)
}

// 最近工单（目前投诉，调解案件查询分页都是用这个）
export const orderList = data => {
  return httpRequest.post('/blade-dcpp/order/list', data, headerObj)
}

// 投诉银行详情
export const getComplaintBankDetail = data => {
  return httpRequest.post('/blade-dcpp/complaint/bank/bankDetail', data, headerObj)
}

// 投诉保险详情
export const getComplaintInsurekDetail = data => {
  return httpRequest.post('/blade-dcpp/complaint/insurance/insuranceDetail', data, headerObj)
}

// 投诉银行工单修改
export const modifyComplaintBank = data => {
  return httpRequest.post('/blade-dcpp/complaint/bank/update', data, headerObj)
}

// 投诉保险工单修改
export const modifyComplaintInsure = data => {
  return httpRequest.post('/blade-dcpp/complaint/insurance/update', data, headerObj)
}

// 投诉工单评价
export const addEvaluation = data => {
  return httpRequest.post('/blade-dcpp/complaint/eval/save', data, headerObj)
}

// 投诉评价分页
export const getComplaintEvaPage = data => {
  return httpRequest.post('/blade-dcpp/complaint/evaPage', data, headerObj)
}

// 调解银行详情
export const getMediationBankDetail = data => {
  return httpRequest.post('/blade-dcpp/mediate/bank/draft/detail', data, headerObj)
}

// 调解保险详情
export const getMediationInsuranceDetail = data => {
  return httpRequest.post('/blade-dcpp/mediate/insurance/draft/detail', data, headerObj)
}

// 调解银行修改
export const mediationBankUpdate = data => {
  return httpRequest.post('/blade-dcpp/mediate/bank/draft/save', data, headerObj)
}

// 调解保险修改
export const mediationInsuranceUpdate = data => {
  return httpRequest.post('/blade-dcpp/mediate/insurance/draft/save', data, headerObj)
}

// 调解评价添加
export const addMediationEvaluation = data => {
  return httpRequest.post('/blade-dcpp/mediate/eval', data, headerObj)
}

// 公众被申请人，问询公众是否愿意调解
export const isAgree = data => {
  return httpRequest.post('/blade-dcpp/mediate/agree', data, headerObj)
}

// 调解评价列表
export const getMediatorEvaPage = data => {
  return httpRequest.post('/blade-dcpp/mediate-eval/page', data, headerObj)
}

// 查看调解文书
export const viewText = data => {
  return httpRequest.post('/blade-dcpp/mediate-text/text', data, headerObj)
}

// 消息通知分页
export const getMessagePage = data => {
  return httpRequest.post('/blade-dcpp/dcpp-remind/page', data, headerObj)
}

// 标记消息已读
export const readMessage = data => {
  return httpRequest.post('/blade-dcpp/dcpp-remind/mark', data, headerObj)
}

// 签名订单过期，重新改变工单状态
export const status9 = data => {
  return httpRequest.post('/blade-dcpp/mediate/stauts/return/status9', data, headerObj)
}

// 消费者确认调解协议书，改变状态
export const status23 = data => {
  return httpRequest.post('/blade-dcpp/mediate/stauts/status23', data, headerObj)
}

// 消费者签名成功，改变状态
export const status20 = data => {
  return httpRequest.post('/blade-dcpp/mediate/stauts/status20', data, headerObj)
}

// 调解协议书修改意见
export const modifyMediatorText = data => {
  return httpRequest.post('/blade-dcpp/mediate-text-log/save', data, headerObj)
}

export const contentList = data => {
  return httpRequest.post('/blade-dcpp/content/list', data, headerObj)
}

export const getBiz = data => {
  return httpRequest.get('/blade-system/dict-biz/list', data, headerObj)
}

// 获取调解会议链接
export const getRoomUrl = data => {
  return httpRequest.post(`/blade-dcpp/mediate/log/getLastMediateLogUserByMediateId?mediateType=${data.mediateType}&mediateId=${data.mediateId}`, {}, headerObj)
}

// 获取腾讯文档链接
export const getTemplateLink = data => {
  return httpRequest.get('/blade-dcpp/callbackMediate/getDocDataByBusId', data, headerObj)
}

// 消费者提交调解协议书修改
export const status32 = data => {
  return httpRequest.post('/blade-dcpp/mediate/stauts/status32', data, headerObj)
}