<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import store from './store'

export default {
  name: 'App',
  mounted() {
    // if (JSON.parse(sessionStorage.getItem("userInfo"))) {
    //   let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    //   let reg = 'DGD'
    //   let cardNo = userInfo.oauth_id.replace(reg, '')
    //   store.dispatch('setUserCardNo', {
    //     cardNo: cardNo
    //   })
    // }
  },
}
</script>

<style lang="less">
@import '@/styles/reset.css';
@import '../src/styles/portal.less';

#app {
  width: 100%;
  height: 100%;
  /* overflow-x: sc; */
}
</style>
