import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/index'),
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index'),
      },
      {
        path: '/column',
        name: '党建专栏',
        redirect: 'column/list',
        component: () => import('@/views/column/index'),
        children: [{
          path: 'list',
          component: () => import('@/views/column/list'),
        }, {
          path: 'detail/:id',
          // component: () => import('@/views/column/detail'),
          component: () => import('@/components/side-list-page/detail'),
        }],
      },
      {
        path: '/count',
        name: '跨境保险纠纷调解合作',
        redirect: 'count/list',
        component: () => import('@/views/count/index'),
        children: [{
          path: 'list',
          component: () => import('@/views/count/list'),
        }, {
          path: 'detail/:id',
          component: () => import('@/views/count/detail'),
        }],
      },
      {
        path: '/search',
        name: '搜索',
        component: () => import('@/views/search/index'),
      },
      {
        path: '/search/detail/:id',
        // name: '搜索详情',
        // component: () => import('@/components/side-list-page/detail'),
        component: () => import('@/views/search/detail'),
      },
      {
        path: '/complaint-entry',
        component: () => import('@/views/home/complaintEntry/index'),
      },
      {
        path: '/complain',
        component: () => import('@/views/home/complain/index'),
      },
      // {
      //   path: '/insure-complain',
      //   component: () => import('@/views/home/insure-complain/index')
      // },
      {
        path: '/mediation-entry',
        component: () => import('@/views/home/mediationEntry/index'),
        beforeEnter: (to, from, next) => {
          if (sessionStorage.getItem('access_token') || sessionStorage.getItem('userInfo')) {
            next()
          } else {
            // next()
            // next(from.path)
            window.confirm('请在网页右上角点击登录，谢谢！')
          }
        },
      },
      {
        path: '/mediation',
        component: () => import('@/views/home/mediation/index'),
        beforeEnter: (to, from, next) => {
          if (sessionStorage.getItem('access_token') || sessionStorage.getItem('userInfo')) {
            next()
          } else {
            // next(from.path)
            window.confirm('请在网页右上角点击登录，谢谢！')
          }
        },
      },
      // {
      //   path: '/insure-mediation',
      //   component: () => import('@/views/home/insure-mediation/index')
      // },
      {
        path: 'inquire',
        component: () => import('@/views/home/inquire/index'),
        beforeEnter: (to, from, next) => {
          if (sessionStorage.getItem('access_token') || sessionStorage.getItem('userInfo')) {
            next()
          } else {
            // next(from.path)
            window.confirm('请在网页右上角点击登录，谢谢！')
          }
        },
      },
      {
        // name: 'about-zh',
        path: 'about-zh',
        name: '关于正和',
        redirect: 'about-zh/introduce',
        component: () => import('@/views/aboutZH/index'),
        children: [
          {
            path: 'introduce',
            name: '正和简介',
            component: () => import('@/views/aboutZH/introduce'),
          },
          {
            path: 'framework',
            name: '组织架构',
            component: () => import('@/views/aboutZH/framework'),
          },
          {
            path: 'rules',
            name: '正和章程',
            component: () => import('@/views/aboutZH/rules'),
          },
          {
            path: 'system',
            name: '工作制度',
            component: () => import('@/views/aboutZH/system'),
          },
          {
            path: 'flow',
            name: '业务流程',
            component: () => import('@/views/aboutZH/flow'),
          },
        ],
      },
      {
        // name: 'about-zh',
        path: 'ED',
        name: '宣传教育',
        redirect: 'ED/finance',
        component: () => import('@/views/ED/index'),
        children: [
          {
            path: 'finance',
            name: '金融知识',
            component: () => import('@/views/ED/finance'),
            // component: () => import('@/components/side-list-page'),
          },
          {
            path: 'finance/detail/:id',
            // component: () => import('@/views/ED/finance/detail'),
            component: () => import('@/components/side-list-page/detail'),
          },
          {
            path: 'case',
            name: '案例分享',
            component: () => import('@/views/ED/case'),
          },
          {
            path: 'case/detail/:id',
            // component: () => import('@/views/ED/case/detail'),
            component: () => import('@/components/side-list-page/detail'),
          },
          {
            path: 'risk',
            name: '风险提示',
            component: () => import('@/views/ED/risk'),
          },
          {
            path: 'risk/detail/:id',
            // component: () => import('@/views/ED/risk/detail'),
            component: () => import('@/components/side-list-page/detail'),
          },
          // {
          //   path: 'detail/:id',
          //   // component: () => import('@/views/ED/risk/detail'),
          //   component: () => import('@/components/side-list-page/detail'),
          // },
        ],
      },
      {
        path: 'news',
        name: '新闻资讯',
        redirect: 'news/supervise',
        component: () => import('@/views/news/index'),
        children: [
          {
            path: 'supervise',
            name: '监管动态',
            component: () => import('@/views/news/supervise'),
            // component: () => import('@/components/side-list-page'),
          },
          {
            path: 'supervise/detail/:id',
            component: () => import('@/components/side-list-page/detail'),
          },
          {
            path: 'zhenghe',
            name: '正和动态',
            component: () => import('@/views/news/zhenghe'),
          },
          {
            path: 'zhenghe/detail/:id',
            component: () => import('@/components/side-list-page/detail'),
          },
          {
            path: 'trade',
            name: '行业动态',
            component: () => import('@/views/news/trade'),
          },
          {
            path: 'trade/detail/:id',
            component: () => import('@/components/side-list-page/detail'),
          },
          // {
          //   path: 'detail/:id',
          //   // component: () => import('@/views/ED/risk/detail'),
          //   component: () => import('@/components/side-list-page/detail'),
          // },
        ],
      },
      {
        path: 'policy',
        name: '政策法规',
        redirect: 'policy/rule',
        component: () => import('@/views/policy/index'),
        children: [
          {
            path: 'rule',
            name: '法律法规',
            component: () => import('@/views/policy/rule'),
            // component: () => import('@/components/side-list-page'),
          },
          {
            path: 'rule/detail/:id',
            component: () => import('@/components/side-list-page/detail'),
          },
          {
            path: 'file',
            name: '规章及规范性文件',
            component: () => import('@/views/policy/file'),
          },
          {
            path: 'file/detail/:id',
            component: () => import('@/components/side-list-page/detail'),
          },
          {
            path: 'read',
            name: '政策解读',
            component: () => import('@/views/policy/read'),
          },
          {
            path: 'read/detail/:id',
            component: () => import('@/components/side-list-page/detail'),
          },
          // {
          //   path: 'detail/:id',
          //   // component: () => import('@/views/ED/risk/detail'),
          //   component: () => import('@/components/side-list-page/detail'),
          // },
        ],
      },
      {
        // name: 'about-zh',
        path: 'service',
        name: '便民服务',
        redirect: 'service/bank',
        component: () => import('@/views/service/index'),
        children: [
          {
            path: 'bank',
            name: '银行机构投诉热线',
            component: () => import('@/views/service/bank'),
          },
          {
            path: 'insure',
            name: '保险机构投诉热线',
            component: () => import('@/views/service/insure'),
          },
        ],
      },
    ],
  },
  {
    path: '/individual-center',
    name: '后台个人中心',
    component: () => import('@/views/individualCenter/index'),
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem('access_token') || sessionStorage.getItem('userInfo')) {
        next()
      } else {
        // next(from.path)
        window.confirm('请在网页右上角点击登录，谢谢！')
      }
    },
  },
]


// 已在当前页面，如还点击跳转至当前页面的nav,无效
// 捕获调用push，replace切换到同一路由时报错的异常
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default new VueRouter({
  // mode: 'history',
  mode: 'hash',
  routes,
})